import styled from "styled-components"

export const Error = styled.div`
  height: 70vh;
  display: flex;
  justify-content: center;
  align-items: center;
  color: var(--darkBlue);

  div {
    text-align: center;
  }
`
