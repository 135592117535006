import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import { Error } from "../styles/ErrorStyles"

const NotFoundPage = () => (
  <Layout>
    <SEO title="404: Not found" />
    <Error>
      <div>
        <h1>404: Not found</h1>
        <p>You just hit a route that doesn&#39;t exist... </p>
      </div>
    </Error>
  </Layout>
)

export default NotFoundPage
